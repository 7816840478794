
import React from "react";
import './style.css'
import Android from '../../assets/images/android.png';
import Ios from '../../assets/images/ios.png';
// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
<h3>Download our wonderful apps</h3>
<a href="https://play.google.com/store/apps/details?id=com.education.leanrbot">
<img href="https://www.iedutainments.com/" src={Android} alt="my image"  style={{width:150, height:70, marginLeft:200,}} />
</a>
<a href="https://apps.apple.com/us/app/learnbots-language-learning/id1527061983">
<img   src={Ios} alt="my image" style={{width:150, height:70,}} />
</a>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by iEdutainments
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
