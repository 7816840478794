
import React ,{Component}from "react";

// reactstrap components
import { Container } from "reactstrap";
import './style.css'
// core components

class IndexHeader extends Component {
  render(){
  return (
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/images/landingpage.jpg") + ")"
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h5 className="presentation-title">Welcome to Learnbots</h5>
              <div className="fog-low">
                {/* <img alt="..." src={require("assets/img/fog-low.png")} /> */}
              </div>
              <div className="fog-low right">
                {/* <img alt="..." src={require("assets/img/fog-low.png")} /> */}
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">
            </h2>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")"
          }}
        />
        {/* <h6 className="category category-absolute">
          Designed and coded by{" "}
          <a
            href="https://www.creative-tim.com?ref=pkr-index-page"
            target="_blank"
          >
            <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/creative-tim-white-slim2.png")}
            />
          </a>
        </h6> */}
      </div>
  );
  }
}
export default IndexHeader;
