
import React,{ Component } from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

import './style.css';
import  { connect } from 'react-redux';
import { logout } from '../../Reduxstate/Admin/action/auth';
class authNavbar extends Component{
  constructor(props){
    super(props);
    this.state = {
      navbarColor : "navbar-transparent",
      navbarCollapse : false
    }
    this.logOut = this.logOut.bind(this)
  }

  logOut(){
    this.props.logout();
  }
  render(){
    return (
      <Navbar className={classnames("border-bottoms", this.state.navbarColor)} expand="lg">
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              href="https://www.iedutainments.com/"
              target="_blank"
              title="Coded by Romy"
            >
             <img onClick="https://www.iedutainments.com/" alt="..." src={require("assets/images/logo-ied.png")}/>
            </NavbarBrand>

          </div>
          <Collapse
            className="justify-content-center"
            navbar

          >
            <Nav navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className='custom-toggle' nav>
                  {localStorage.getItem('username')}
              </DropdownToggle>
              <DropdownMenu>
                  <DropdownItem onClick = {()=>this.logOut()}>
                      Sign Out
                  </DropdownItem>
              </DropdownMenu>
          </UncontrolledDropdown>
            </Nav>
          </Collapse>
      </Navbar>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.user,
  users: state.user.user,
});

export default connect(
  mapStateToProps,
  { logout }
)(authNavbar);
