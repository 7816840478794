import { 
    REGISTER_SUCEESS, 
    LOGIN_SUCEESS
} from "../action/type";

    const initialState = {
        isAuthenticated : false,
        user: {},
        flag: {}
    };


    export default function(state = initialState, action) {
        switch (action.type) {
            case REGISTER_SUCEESS:
                return {
                    ...state,
                    flag: action.payload
                };
            case LOGIN_SUCEESS:
                return {
                    ...state,
                    user: action.payload
                };
            default:
                return state;
        }
    }