
import React,{ Component } from "react";

// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";
import  { connect } from 'react-redux';
import { login } from '../../Reduxstate/Admin/action/auth';


class SignIn extends Component {
  constructor(props){
    super(props);
    this.state = {
      email : "",
      password : ""
    }
  }

  onChange = (e) => {
    this.setState({[e.target.name]:e.target.value})
  }

  submitForm = (e) =>{
    e.preventDefault();
        let requestBody = {
          email: this.state.email,
          password: this.state.password,
        }
        this.props.login(requestBody);
  }

  componentWillReceiveProps(nextProps){
    setInterval(() => {
      if(nextProps.users != ""){
        window.location.href = "/selectLanguage";
      }
    }, 1000);
  }

  render(){
    return (
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/images/background.png") + ")"
          }}
        >
          <div className="filter" />
          <Container className="sign-container">
            <Row>
              <Col className="ml-5 mr-auto left" lg="4" >
                <Card className="card-register ml-auto mr-auto">
                  <Row className="ml-auto mr-auto">
                    <div className="first-title title mb-0">LEARNBOTS</div>
                    <div className="second-title title mb-0">®</div>
                  </Row>
                  <hr className="solid"></hr>
                  <Form  onSubmit = {this.submitForm}>
                    <label>Email</label>
                    <Input className="sign-control" placeholder="Email" type="text" name = "email" onChange = {this.onChange}/>
                    <label>Password</label>
                    <Input className="sign-control" placeholder="Password" type="password" name = "password" onChange = {this.onChange}/>
                    <Button block className="btn-round btn-login" type = "submit" style={{backgroundColor: '#ffffff', color: '#ff960d'}}>
                      Sign In
                    </Button>

                  </Form>
                  <div className="forgot">
                    <Button
                      className="btn-link btn-sign"
                      href="/register"
                    >
                      Create an Account?
                    </Button>
                  </div>
                  {/* <div className="forgot">
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Forgot password?
                    </Button>
                  </div> */}
                </Card>
              </Col>
            </Row>
          </Container>
          <div className="footer register-footer text-center">
            <h6>
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by Creative Tim
            </h6>
          </div>
        </div>
    )
  }
}
const mapStateToProps = state => ({
  auth: state.user,
  users: state.user.user,
});

export default connect(
  mapStateToProps,
  { login }
)(SignIn);
