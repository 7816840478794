import React,{Component} from "react";
import {Link} from 'react-router-dom'
import './style.css';
import IndexNavbar from "components/Navbars/authNavbar";
import DemoFooter from "components/Footers/DemoFooter.js";
import { browserHistory } from 'react-router'
import { getOneVerbs } from "../../Reduxstate/Admin/action/verb";
import { connect } from "react-redux";
import TranslateLanguage from "./translatelang.json";
import {Modal, ModalHeader, ModalBody, ModalFooter,Button,Form } from 'reactstrap';

import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"

import AudioPlayer from 'react-h5-audio-player';
import '../../../node_modules/react-h5-audio-player/lib/styles.css';

import TranslateImage from '../../assets/images/tool/assets_icons_star.png';
import PopImage from '../../assets/images/popup.png';
import Android from '../../assets/images/android.png';
import Ios from '../../assets/images/ios.png';
import Play from '../../assets/images/tool/assets_icons_play.png';
import Point from '../../assets/images/icon/point.png';
import Setting from '../../assets/images/tool/setting.png';
import Eye from '../../assets/images/tool/assets_icons_eye.png';
import Index from '../..//assets/images/tool/assets_icons_lang_setting.png';
import Audio from '../../assets/images/tool/assets_icons_audio_play.png';
import Favorite from '../../assets/images/tool/assets_icons_favorite.png';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class  Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      Translate_Language : TranslateLanguage,
      modal : false,
      imageModal : false,
      videoModal : false,
      showMe : true,
      Image_path : localStorage.getItem("image_path"),
      first_langId : localStorage.getItem("first_langId"),
      current_word : "",
      table_th : [],
      pronom : [],
      present_simple : [],
      past_continuous : [],
      past_simple : [],
      future : [],
      conditional : [],
      present_perfect : [],
      videoImage : [],
      audioUrl : ""
    }
    this.toggle = this.toggle.bind(this);
    this.imagetoggle = this.imagetoggle.bind(this);

  }

  componentWillMount() {
      this.imagetoggle();
      setInterval(()=>{
          this.imagetoggle();
      }, 300000)
     // setTimeout( this.imagetoggle(), 30000);
  }

    componentDidMount(){
    let data = {
      lang : this.props.match.params.lang,
      infinitiv : this.props.match.params.english
    }
    this.props.getOneVerbs(data);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  imagetoggle() {
        this.setState({
            imageModal: !this.state.imageModal,
        });
        console.log('hello');
    }
  onTraslate = (lang) => {
    this.setState({
      modal : !this.state.modal
    });
    let data = {
      lang : lang,
      infinitiv : this.props.match.params.english
    }
    this.props.getOneVerbs(data);
  }

  onfirstTraslate = (lang) => {
    let data = {
      lang : lang,
      infinitiv : this.props.match.params.english
    }
    this.props.getOneVerbs(data);
  }

  componentWillReceiveProps(nextProps){
    console.log(nextProps.currentData.data);
    console.log("aaaaaaaaaa",this.state.videoImage.length);
    var data = {
      ...nextProps.currentData.data.tempus,
      audio : Audio
    }
    if(this.state.videoImage.length === 0) {
      this.setState({
        videoImage : nextProps.currentData.data.video_image
      })
    }
    this.setState({
      current_word : nextProps.currentData.data.current_word['infinitiv_text'],
      table_th : data,
      pronom : nextProps.currentData.data.pronom,
      present_simple : nextProps.currentData.data.present_simple_verbs,
      past_continuous : nextProps.currentData.data.past_continuous_verbs,
      past_simple : nextProps.currentData.data.past_simple_verbs,
      future : nextProps.currentData.data.future_verbs,
      conditional : nextProps.currentData.data.conditional_verbs,
      present_perfect : nextProps.currentData.data.present_perfect_verbs,

    })
  }

  audiotest = (param) => {
    let audioUrl = "https://verb-"+this.props.match.params.lang+".s3.eu-west-2.amazonaws.com/audio/" + param + ".mp3";
    this.setState({
      audioUrl : audioUrl
    })
  }

  VideoPlay = () => {
    this.setState({
      videoModal : !this.state.videoModal
    })
  }

  ImagePlay = () => {
    this.setState({
      imageModal : !this.state.imageModal
    })
  }

  operation(){
    this.setState({showMe:!this.state.showMe})
  }

  render(){
    if (localStorage.getItem("username") == null) {
      window.location.href = "/login";
    }

    return (
      <div>
        <IndexNavbar />
        <div className="main">
            <div className = "home-index">
                <ul>
                    <li>

                        {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL' && this.props.match.params.lang != 'GD' && this.props.match.params.lang != 'CY' && this.props.match.params.lang != 'GL' && this.props.match.params.lang != 'NL' && this.props.match.params.lang != 'EO') && <img src = {TranslateImage} onClick = {this.toggle}/> }
                    </li>
                    <li onClick = {()=>this.onfirstTraslate(this.state.first_langId)}>
                        <img src = {this.state.Image_path} />
                    </li>
                    <li></li>
                    <li className = "text-word">
                        <p>{this.state.current_word}</p>
                    </li>
                    <li></li>
                    <li onClick={browserHistory.goBack} >
                        <img src = {Index}/>
                    </li>
                    <li onClick = {()=>this.operation()}>
                        <img src = {Eye}/>
                    </li>

                </ul>
            </div>
            <div className = "table-style">
            <table className="table">
                <thead>
                {Object.keys(this.state.table_th).length == undefined ? <FadeLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} />:
                  <tr>
                    <th scope="col" className = "img-audio">
                      <img src = {this.state.table_th.audio} alt = "image"/>
                    </th>
                    <th scope="col" className = "text-present">{this.state.table_th.tempus1}</th>
                    <th scope="col" className = "text-present">{this.state.table_th.tempus2}</th>
                    <th scope="col" className = "text-present">{this.state.table_th.tempus3}</th>
                    <th scope="col" className = "text-present">{this.state.table_th.tempus4}</th>
                    <th scope="col" className = "text-present">{this.state.table_th.tempus5}</th>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <th scope="col" className = "text-present">{this.state.table_th.tempus6}</th>}
                  </tr>
                }
                </thead>

                {Object.keys(this.state.table_th).length == undefined ? <FadeLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} />:
                <tbody>
                  {this.state.showMe?<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom1}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj1_audio)}>{this.state.present_simple.conj1_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj7_audio)}>{this.state.past_continuous.conj7_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj13_audio)}>{this.state.past_simple.conj13_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj19_audio)}>{this.state.future.conj19_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj25_audio)}>{this.state.conditional.conj25_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj31_audio)}>{this.state.present_perfect.conj31_text}</td>}
                  </tr>:<tr>
                      <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom1}</td>
                      <td onClick = {() => this.audiotest(this.state.present_simple.conj1_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_continuous.conj7_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_simple.conj13_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.future.conj19_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.conditional.conj25_audio)}/>
                      {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj31_audio)}/>}
                  </tr>}
                  {this.state.showMe?<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom2}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj2_audio)}>{this.state.present_simple.conj2_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj8_audio)}>{this.state.past_continuous.conj8_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj14_audio)}>{this.state.past_simple.conj14_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj20_audio)}>{this.state.future.conj20_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj26_audio)}>{this.state.conditional.conj26_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj32_audio)}>{this.state.present_perfect.conj32_text}</td>}
                  </tr>:<tr>
                      <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom2}</td>
                      <td onClick = {() => this.audiotest(this.state.present_simple.conj2_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_continuous.conj8_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_simple.conj14_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.future.conj20_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.conditional.conj26_audio)}/>
                      {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj32_audio)}/>}
                  </tr>}
                {this.state.showMe?  <tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom3}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj3_audio)}>{this.state.present_simple.conj3_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj9_audio)}>{this.state.past_continuous.conj9_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj15_audio)}>{this.state.past_simple.conj15_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj21_audio)}>{this.state.future.conj21_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj27_audio)}>{this.state.conditional.conj27_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj33_audio)}>{this.state.present_perfect.conj33_text}</td>}
                  </tr>:<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom3}</td>
                    <td onClick = {() => this.audiotest(this.state.present_simple.conj3_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.past_continuous.conj9_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.past_simple.conj15_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.future.conj21_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.conditional.conj27_audio)}/>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj33_audio)}/>}
                </tr>}
                  {this.state.showMe?<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom4}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj4_audio)}>{this.state.present_simple.conj4_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj10_audio)}>{this.state.past_continuous.conj10_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj16_audio)}>{this.state.past_simple.conj16_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj22_audio)}>{this.state.future.conj22_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj28_audio)}>{this.state.conditional.conj28_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj34_audio)}>{this.state.present_perfect.conj34_text}</td>}
                  </tr>:<tr>
                      <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom4}</td>
                      <td onClick = {() => this.audiotest(this.state.present_simple.conj4_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_continuous.conj10_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_simple.conj16_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.future.conj22_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.conditional.conj28_audio)}/>
                      {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj34_audio)}/>}
                  </tr>}
                  {this.state.showMe?<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom5}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj5_audio)}>{this.state.present_simple.conj5_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj11_audio)}>{this.state.past_continuous.conj11_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj17_audio)}>{this.state.past_simple.conj17_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj23_audio)}>{this.state.future.conj23_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj29_audio)}>{this.state.conditional.conj29_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj35_audio)}>{this.state.present_perfect.conj35_text}</td>}
                  </tr>:<tr>
                      <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom5}</td>
                      <td onClick = {() => this.audiotest(this.state.present_simple.conj5_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_continuous.conj11_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.past_simple.conj17_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.future.conj23_audio)}/>
                      <td onClick = {() => this.audiotest(this.state.conditional.conj29_audio)}/>
                      {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj35_audio)}/>}
                  </tr>}
                {this.state.showMe?  <tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom6}</td>
                    <td onClick = {()=>this.audiotest(this.state.present_simple.conj6_audio)}>{this.state.present_simple.conj6_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_continuous.conj12_audio)}>{this.state.past_continuous.conj12_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.past_simple.conj18_audio)}>{this.state.past_simple.conj18_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.future.conj24_audio)}>{this.state.future.conj24_text}</td>
                    <td onClick = {()=>this.audiotest(this.state.conditional.conj29_audio)}>{this.state.conditional.conj29_text}</td>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {()=>this.audiotest(this.state.present_perfect.conj36_audio)}>{this.state.present_perfect.conj36_text}</td>}
                  </tr>:<tr>
                    <td style={{backgroundColor: '#ffffff'}}>{this.state.pronom.pronom6}</td>
                    <td onClick = {() => this.audiotest(this.state.present_simple.conj6_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.past_continuous.conj12_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.past_simple.conj18_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.future.conj24_audio)}/>
                    <td onClick = {() => this.audiotest(this.state.conditional.conj29_audio)}/>
                    {(this.props.match.params.lang != 'RU' && this.props.match.params.lang != 'SL' && this.props.match.params.lang != 'PL') && <td onClick = {() => this.audiotest(this.state.present_perfect.conj36_audio)}/>}
                </tr>}
                  </tbody>
                }

              </table>
            </div>
            <AudioPlayer
              autoPlay
              src={this.state.audioUrl}
              onPlay={e => console.log("onPlay")}
              hidden="true"
            />
            {/* translate Modal */}
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="large" size="lg">
              <ModalHeader style={{backgroundColor: '#ff8d30', color: "white", fontSize: "30px", textAlign: "left", fontWeight: "bold",}}toggle={this.toggle}>Translate Conjugations <br /> <span style={{color: "white", fontSize: "20px", textAlign: "left",}}> Translate all the conjugations into any of the languages below.</span></ModalHeader>
              <ModalBody>
                <div className = "row select-language">
                  {
                    this.state.Translate_Language.Language.length == undefined ? <p>sorry</p> :
                    this.state.Translate_Language.Language.map((item,i)=>{
                      return (
                              <div key = {i}  className = "col-md-2 col-sm-4 col-xs-6" onClick = {()=>this.onTraslate(item.lang_id)}>
                                  <div>
                                      <img src =  {item.image_path} className = "img-responsive" alt = "flag"/>
                                  </div>
                                  <h6>
                                      {item.name}
                                  </h6>
                              </div>
                        )
                    })
                  }
                </div>
              </ModalBody>

            </Modal>
            {/* Image Modal */}
            <Modal isOpen={this.state.imageModal} toggle={this.ImagePlay} className="large" size="lg">
              <ModalHeader toggle={this.ImagePlay}></ModalHeader>
              <ModalBody style={{
                backgroundImage:
                  "url(" + require("assets/images/popup.png") + ")"
              }}>

              <br />
              <br />
                <div className = "row select-language">

                <a href="https://play.google.com/store/apps/details?id=com.education.leanrbot">
                <img href="https://www.iedutainments.com/" src={Android} alt="my image"  style={{width:150, height:70, marginLeft:200,}} />
                </a>
                <a href="https://apps.apple.com/us/app/learnbots-language-learning/id1527061983">
                <img   src={Ios} alt="my image" style={{width:150, height:70,}} />
                </a>


<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />


                </div>
              </ModalBody>

            </Modal>
            {/* Video Modal */}
            <Modal isOpen={this.state.videoModal} toggle={this.VideoPlay} className="large" size="lg">
              <ModalHeader toggle={this.VideoPlay}></ModalHeader>
              <ModalBody>
                  <Player
                    poster={this.state.videoImage.length === 0?"":"https://verb-"+this.props.match.params.lang.toLowerCase()+".s3.eu-west-2.amazonaws.com/images/" +this.state.videoImage.image_768x1024.replace('768x1024.jpg', '1024x693.png')} onClick = {()=>this.ImagePlay()}
                    src={this.state.videoImage.length === 0?"":"https://verb-"+this.props.match.params.lang.toLowerCase()+".s3.eu-west-2.amazonaws.com/movies/" + this.props.match.params.lang.toLowerCase() + "-3-"+this.state.videoImage.idx+".mp4"}
                   >
                  </Player>
              </ModalBody>

            </Modal>

              {/* PopUp Modal */}
                          <DemoFooter />
        </div>
      </div>
    );
  }

}
const mapStateToProps = state => ({
  varbData : state.verb.allVerbData,
  currentData : state.verb.targetData
});

export default connect(
mapStateToProps, {getOneVerbs}
)(Home);
