import axios from 'axios';
import { 
    Base_URL,
    GET_VERB_ERROR,
    GET_VERB_SUCCESS,
    GET_ONE_VERB_SUCCESS,
    GET_SEARCH_VERB_SUCCESS
} from "./type";

export const getAllVerbs = (lang) => dispatch => {
    axios.post(Base_URL + '/verb/readAllOfLang',lang)
        .then( res => {
            console.log(res.data);
            dispatch({
                type:GET_VERB_SUCCESS,
                payload:res.data
              })
        }
    )
    .catch(err=> dispatch({
        type:GET_VERB_ERROR,
        payload:"error"
    }))
}

export const getOneVerbs = (data) => dispatch => {
    axios.post(Base_URL + '/verb/readOfInfinitiv',data)
        .then( res => {
            dispatch({
                type:GET_ONE_VERB_SUCCESS,
                payload:res
              })
        }
    )
    .catch(err=> dispatch({
        type:GET_VERB_ERROR,
        payload:"error"
    }))
}
export const getSearchVerbs = (data) => dispatch => {
    axios.post(Base_URL + '/searchInfinitiv',data)
        .then( res => {
            dispatch({
                type:GET_SEARCH_VERB_SUCCESS,
                payload:res.data
              })
        }
    )
    .catch(err=> dispatch({
        type:GET_VERB_ERROR,
        payload:"error"
    }))
}