import { 
    
    GET_VERB_ERROR,
    GET_VERB_SUCCESS,
    GET_ONE_VERB_SUCCESS,
    GET_SEARCH_VERB_SUCCESS
  } from "../action/type";
  
    // const isEmpty = require("is-empty");
  
    const initialState = {
        allVerbData : {},
        targetData : {},
        searchData : {}     
    };
  
  
    export default function(state = initialState, action) {
      switch (action.type) {
        case GET_VERB_SUCCESS:
          return {
            ...state,
            allVerbData: action.payload
          };
        case GET_ONE_VERB_SUCCESS:
          return {
            ...state,
            targetData: action.payload
          };
        case GET_SEARCH_VERB_SUCCESS:
          return {
            ...state,
            searchData: action.payload
          };
        
        default:
          return state;
      }
    }