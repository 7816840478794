
import React,{ Component } from "react";

// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";
import  { connect } from 'react-redux';
import { registerUser } from '../../Reduxstate/Admin/action/auth';
import { ToastContainer, toast } from 'react-toastify';
class SignUp extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : "",
      email : "",
      password : "",
      message : ""
    }
  }
  onChange = (e) => {
    this.setState({[e.target.name]:e.target.value})
  }

  submitForm = (e) =>{
    e.preventDefault();


        let requestBody = {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
        }
        this.props.registerUser(requestBody);

  }

  componentWillReceiveProps(nextprops){
    console.log(nextprops);
    if(nextprops.data.message === "Successfully registered."){

        this.setState({
          message : nextprops.data.message
        })
        setTimeout(() => {
          window.location.href = "/login"
        }, 2000);
    }
  }
  render(){
    if(this.state.message == "Successfully registered."){
      toast.success(this.state.message);
      // this.props.getCard();
      this.state.message = null;
  }
    return (
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/images/background.png") + ")"
          }}
        >
          <div className="filter" />
          <Container className="sign-container">
            <Row>
              <Col className="ml-5 mr-auto" lg="4">
                <Card className="card-register ml-auto mr-auto">
                  <Row className="ml-auto mr-auto">
                    <div className="first-title title mb-0">LEARNBOTS</div>
                    <div className="second-title title mb-0">®</div>
                  </Row>
                  <hr className="solid"></hr>
                  <Form onSubmit = {this.submitForm}>
                    <label>Username</label>
                    <Input className="sign-control" placeholder="Username" type="text" name = "username" onChange = {this.onChange} />
                    <label>Email</label>
                    <Input className="sign-control" placeholder="Email" type="email" name = "email" onChange = {this.onChange}/>
                    <label>Password</label>
                    <Input className="sign-control" placeholder="Password" type="password" name = "password" onChange = {this.onChange}/>
                    <Button block className="btn-round" color="danger" type = "submit">
                      Sign Up
                    </Button>

                  </Form>
                  <div className="forgot">
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/login"
                    >
                      Back
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
          <ToastContainer />
          <div className="footer register-footer text-center">
            <h6>
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by Creative Tim
            </h6>
          </div>
        </div>
    )
  }
}
const mapStateToProps = state => ({

  auth: state.auth,
  data: state.user.flag,

  // errors: state.user.flag
});

export default connect(
  mapStateToProps,
  { registerUser }
)(SignUp);
