import axios from 'axios';
import { 
    REGISTER_SUCEESS,
    Base_URL,
    GET_ERRORS,
    LOGIN_SUCEESS
} from "./type";

export const registerUser = (userData) => dispatch => {
    axios.post(Base_URL + '/auth/register', userData)
        .then( res => {
            console.log(res);
            dispatch({
                type:REGISTER_SUCEESS,
                payload:res.data
            })
        }
    )
    .catch(err=> dispatch({
        type:GET_ERRORS,
        payload:"error"
    }))
}
export const login = (userData) => dispatch => {
    axios.post(Base_URL + '/auth/login', userData)
        .then( res => {
            console.log(res);
            localStorage.setItem("id", res.data.data.id);
            localStorage.setItem("username", res.data.data.username);
            dispatch({
                type:LOGIN_SUCEESS,
                payload:res.data
            })
        }
    )
    .catch(err=> dispatch({
        type:GET_ERRORS,
        payload:"error"
    }))
}
export const logout = () => dispatch => {
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    window.location.href = "/login";
}


